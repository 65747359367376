.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #F9F9FE !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
background-color: #F9F9FE !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 2px;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 30px;
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #c7c4c4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ece7e7;
}